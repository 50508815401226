<template>
  <div>
    <v-data-table
      :headers="getHeaders()"
      :items="tableData"
      disable-pagination
      fixed-header
      :options.sync="options"
      calculate-widths
      height="calc(100vh - 254px)"
      hide-default-footer
      :server-items-length="1"
      disable-filtering
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar dense class="custom-toolbar" flat>
          <v-toolbar-title style="font-size: 15px"
            >Tổng: {{ pagination.total }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn
            @click="$emit('handle-create')"
            class="mx-2"
            x-small
            fab
            dark
            color="indigo"
          >
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.type="{ item }">
        {{ item.type == "M" ? "Giữa kì" : "Cuối kì" }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
          x-small
          @click="$emit('handle-edit', item)"
          fab
          dark
          color="primary"
        >
          <v-icon dark>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          class="ml-2"
          x-small
          @click="handleDelete(item.id)"
          fab
          dark
          color="error"
        >
          <v-icon dark>mdi-delete</v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="$emit('handle-reset')">Refresh</v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card :loading="loading">
        <v-card-title>Thông tin giảng viên</v-card-title>
        <v-card-text v-if="selectedLecturer">
          <p>Số môn học: {{ selectedLecturer.subjectsCount }}</p>
          <p>Số đề gốc: {{ selectedLecturer.originalExamsCount }}</p>
          <p>Số kì thi: {{ selectedLecturer.examinationsCount }}</p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { destroy, show } from "@/api/assistant";
import dataTableMixin from "@/mixins/crud/data-table";
export default {
  mixins: [dataTableMixin(destroy)],
  computed: {
    headers() {
      return [
        { text: "Tên", value: "name" },
        { text: "Email", value: "email" },

        {
          text: "Hành động",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ];
    },
  },
  data() {
    return {
      dialog: false,
      selectedLecturer: null,
      loading: false,
    };
  },
  methods: {
    async showDialog(id) {
      this.dialog = true;
      this.loading = true;
      const { data } = await show(id);
      this.selectedLecturer = data;
      this.loading = false;
    },
  },
};
</script>
