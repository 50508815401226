import request from "@/utils/request";
export function index(params) {
  return request({
    url: "/assistants",
    method: "get",
    params,
  });
}
export function update(id, data) {
  return request({
    url: "/assistants/" + id,
    method: "put",
    data,
  });
}
export function show(id) {
  return request({
    url: "/assistants/" + id,
    method: "get",
  });
}
export function store(data) {
  return request({
    url: "/assistants",
    method: "post",
    data,
  });
}
export function destroy(id) {
  return request({
    url: "/assistants/" + id,
    method: "delete",
  });
}

export function monitoringAssistant(params) {
  return request({
    url: "/assistant/monitoring",
    method: "get",
    params
  });
}